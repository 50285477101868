<template>
  <v-layout max-width class="flex-column" style="min-height:100%;">
    <div class="flex-0 flex-shrink-0 flex-y-center flex-justify-between px-2 white" style>
      <v-tabs v-model="tab" @change="active=[trees[0].id]">
        <v-tab :href="`#1`">文件夹配置</v-tab>
        <v-tab :href="`#2`">进度配置</v-tab>
      </v-tabs>
      <div class="flex-y-center" style="width:200px;">
        <span class="flex-0 flex-shrink-0">进度类型：</span>
        <v-menu bottom max-height="300" offset-overflow transition="slide-y-transition" offset-y>
          <template v-slot:activator="{ on }">
            <div class color="primary" style v-on="on">
              项目文件夹
              <v-icon>mdi-menu-down</v-icon>
              <!-- <input placeholder="进度类型" class=" max-width" type="text" v-model="search.data.project_name" /> -->
            </div>
          </template>
          <v-list class="pa-0 radius-0" subheader>
            <v-list-item>
              <v-list-item-title>项目文件夹</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>中心文件夹</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <!-- <div class=" flex-1"> -->

    <div v-if="tab==1" class="flex-1" style="border-top:1px solid #e8e8e8">
      <div class="flex-1 flex-justify-between max-height" style="min-height:100%;">
        <div
          class="flex-0 flex-shrink-0 white"
          style="width:300px;border-right:1px solid #e1e1e1 !important;"
        >
          <div class="flex-y-center mt-5 px-3 mb-3">
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
              @click="dialog.show=true"
            >
              <v-icon size="20">mdi-plus</v-icon>
            </v-btn>
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
            >
              <v-icon size="20">mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
            >
              <v-icon size="20">mdi-reply-outline</v-icon>
            </v-btn>
          </div>
          <v-treeview
            v-model="selection"
            class="mx-3"
            :active.sync="active"
            item-children="children"
            activatable
            :items="trees"
            :open.sync="open"
            item-text="departName"
            transition
            selection-type="independent"
            selectable
            @update:active="chooseTree"
          />
        </div>
        <div class="flex-1">
          <v-tabs v-model="rightTab" class="border-bottom">
            <v-tab>基本信息</v-tab>
            <v-tab>权限</v-tab>
            <v-tab>审批</v-tab>
          </v-tabs>
          <v-tabs-items v-model="rightTab">
            <v-tab-item class="mt-12 ml-2">
              <div class="mb-8 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>文件夹名称：
                </div>
                <input
                  v-model="information.username"
                  class="default-input py-1 subtitle-1"
                  style="width:350px !important;"
                  placeholder="文件夹名称"
                  type="text"
                >
              </div>
              <div class="mb-4 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>允许前台创建子文件夹：
                </div>
                <v-radio-group v-model="information.status" row dense class="mt-0">
                  <v-radio label="是" value="1" class="mr-8" />
                  <v-radio label="否" value="0" />
                </v-radio-group>
                <v-checkbox v-model="information.selected" class="mt-0 ml-5" label="应用到子文件夹" />
              </div>
              <div class="mb-12 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                >
                  <span class="red--text ml-1">*</span>排序：
                </div>
                <input
                  v-model="information.s"
                  class="default-input py-1 subtitle-1"
                  style="width:350px !important;"
                  placeholder="排序"
                  type="number"
                >
              </div>
              <div class="mb-10 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                />
                <v-btn color="#1976d2" depressed dark width="100">保存</v-btn>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="headers"
                :items="list"
                :items-per-page="search.pageSize"
                class="pb-5 max-width"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.readonly="{item}">
                  <v-checkbox v-model="jurisdiction.readonly.selected" dense :value="item.id" />
                </template>
                <template v-slot:item.writable="{item}">
                  <v-checkbox v-model="jurisdiction.writable.selected" dense :value="item.id" />
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item class="mt-12">
              <div class="mb-4 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>允许需要审批：
                </div>
                <v-radio-group v-model="information.status" row dense class="mt-0">
                  <v-radio label="是" value="1" class="mr-8" />
                  <v-radio label="否" value="0" />
                </v-radio-group>
                <v-checkbox v-model="information.selected" class="mt-0 ml-5" label="应用到子文件夹" />
              </div>
              <div class="mb-10 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                />
                <v-btn color="#1976d2" depressed dark width="100" text>设置审批流程</v-btn>
                <v-dialog v-model="process.show" fullscreen>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" text v-on="on">设置审批流程</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <div class="max-width flex-justify-between">
                        <span class="font-weight-bold">批量激活</span>
                        <span @click="process.show=false">关闭</span>
                      </div>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="py-5" />
                  </v-card>
                </v-dialog>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
    <div v-else class="flex-1 flex-align-start" style="margin-top:14px;">
      <v-row dense>
        <v-col :md="progress.chooseId?5:12">
          <v-card class="elevation-0">
            <div class="flex-y-center pt-4 px-3 mb-3">
              <v-btn
                color="#00bea9"
                depressed
                dark
                small
                style="height:32px; padding:0;"
                min-width="30"
                class="ml-2"
                @click="dialog.show=true"
              >
                <v-icon size="20">mdi-plus</v-icon>
              </v-btn>
              <v-btn
                color="#00bea9"
                depressed
                dark
                small
                style="height:32px; padding:0;"
                min-width="30"
                class="ml-2"
                @click="dialog.show=true"
              >
                <v-icon size="20">mdi-plus</v-icon>
              </v-btn>
            </div>
            <v-data-table
              :headers="progress.headers"
              :items="progress.list"
              :items-per-page="search.pageSize"
              class="flex-1 pb-5 max-width header-grey px-4"
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
            >
              <!-- <template v-slot:item="{expand,item}"> -->
              <template v-slot:item.radio="{item}">
                <v-radio-group
                  v-model="progress.chooseId"
                  dense
                  height="18px"
                  class="ma-0 pa-0"
                  column
                >
                  <v-radio :value="item.id" class="mt-5" />
                </v-radio-group>
              </template>
              <template v-slot:item.active="{item}">
                <a class="mr-2" @click="showEdit(item)">编辑</a>
                <a class="mr-2" @click="showEdit(item)">删除</a>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col v-if="progress.chooseId" md="7" class>
          <v-card class="elevation-0 ml-1">
            <div class="flex-y-center pt-4 px-3 mb-3">
              <v-btn
                color="#00bea9"
                depressed
                dark
                small
                style="height:32px; padding:0;"
                min-width="30"
                class="ml-2"
                @click="dialog.show=true"
              >
                <v-icon size="20">mdi-plus</v-icon>
              </v-btn>
              <v-btn
                color="#00bea9"
                depressed
                dark
                small
                style="height:32px; padding:0;"
                min-width="30"
                class="ml-2"
                @click="dialog.show=true"
              >
                <v-icon size="20">mdi-plus</v-icon>
              </v-btn>
            </div>
            <div class="flex-y-center">
              <v-data-table
                :headers="progress.headers"
                :items="progress.list"
                :items-per-page="search.pageSize"
                class="flex-1 pb-5 max-width header-grey px-4"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >
                <!-- <template v-slot:item="{expand,item}"> -->
                <template v-slot:item.active="{item}">
                  <a class="mr-2" @click="showEdit(item)">编辑</a>
                  <a class="mr-2" @click="showEdit(item)">删除</a>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div v-if="hehe" class="flex-1 flex-justify-between max-height" style="min-height:100%;">
      <div
        class="flex-0 flex-shrink-0 white"
        style="width:300px;border-right:1px solid #e1e1e1 !important;"
      >
        <template v-if="tab==1">
          <div class="flex-y-center mt-5 px-3 mb-3">
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
              @click="dialog.show=true"
            >
              <v-icon size="20">mdi-plus</v-icon>
            </v-btn>
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
            >
              <v-icon size="20">mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
            >
              <v-icon size="20">mdi-reply-outline</v-icon>
            </v-btn>
          </div>
          <v-treeview
            v-model="selection"
            class="mx-3"
            :active.sync="active"
            item-children="children"
            activatable
            :items="trees"
            :open.sync="open"
            item-text="departName"
            transition
            selection-type="independent"
            selectable
            @update:active="chooseTree"
          />
        </template>
        <template v-else>
          <div class="flex-y-center mt-5 px-3 mb-3">
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
              @click="dialog.show=true"
            >
              <v-icon size="20">mdi-plus</v-icon>
            </v-btn>
            <v-btn
              color="#00bea9"
              depressed
              dark
              small
              style="height:32px; padding:0;"
              min-width="30"
              class="ml-2"
              @click="dialog.show=true"
            >
              <v-icon size="20">mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-treeview
            class="mx-3"
            :active.sync="active"
            item-children="children"
            activatable
            :items="trees"
            :open.sync="open"
            item-text="departName"
            transition
            @update:active="chooseTree"
          />
        </template>
      </div>
      <div class="flex-1">
        <template v-if="tab==1">
          <v-tabs v-model="rightTab" class="border-bottom">
            <v-tab>基本信息</v-tab>
            <v-tab>权限</v-tab>
            <v-tab>审批</v-tab>
          </v-tabs>
          <v-tabs-items v-model="rightTab">
            <v-tab-item class="mt-12 ml-2">
              <div class="mb-8 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>文件夹名称：
                </div>
                <input
                  v-model="information.username"
                  class="default-input py-1 subtitle-1"
                  style="width:350px !important;"
                  placeholder="文件夹名称"
                  type="text"
                >
              </div>
              <div class="mb-4 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>允许前台创建子文件夹：
                </div>
                <v-radio-group v-model="information.status" row dense class="mt-0">
                  <v-radio label="是" value="1" class="mr-8" />
                  <v-radio label="否" value="0" />
                </v-radio-group>
                <v-checkbox v-model="information.selected" class="mt-0 ml-5" label="应用到子文件夹" />
              </div>
              <div class="mb-12 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                >
                  <span class="red--text ml-1">*</span>排序：
                </div>
                <input
                  v-model="information.s"
                  class="default-input py-1 subtitle-1"
                  style="width:350px !important;"
                  placeholder="排序"
                  type="number"
                >
              </div>
              <div class="mb-10 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                />
                <v-btn color="#1976d2" depressed dark width="100">保存</v-btn>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="headers"
                :items="list"
                :items-per-page="search.pageSize"
                class="pb-5 max-width"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.readonly="{item}">
                  <v-checkbox v-model="jurisdiction.readonly.selected" dense :value="item.id" />
                </template>
                <template v-slot:item.writable="{item}">
                  <v-checkbox v-model="jurisdiction.writable.selected" dense :value="item.id" />
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item class="mt-12">
              <div class="mb-4 flex-align-start">
                <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>允许需要审批：
                </div>
                <v-radio-group v-model="information.status" row dense class="mt-0">
                  <v-radio label="是" value="1" class="mr-8" />
                  <v-radio label="否" value="0" />
                </v-radio-group>
                <v-checkbox v-model="information.selected" class="mt-0 ml-5" label="应用到子文件夹" />
              </div>
              <div class="mb-10 flex-align-start">
                <div
                  class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                  style="width:170px;"
                />
                <v-btn color="#1976d2" depressed dark width="100" text>设置审批流程</v-btn>

                <v-dialog v-model="process.show" fullscreen>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" text v-on="on">设置审批流程</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <div class="max-width flex-justify-between">
                        <span class="font-weight-bold">批量激活</span>
                        <span @click="process.show=false">关闭</span>
                      </div>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="py-5" />
                    <!-- <v-list
                          three-line
                          subheader
                        >
                          <v-subheader>User Controls</v-subheader>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Content filtering</v-list-item-title>
                              <v-list-item-subtitle>Set the content filtering level to restrict apps that can be downloaded</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Password</v-list-item-title>
                              <v-list-item-subtitle>Require password for purchase or use password to restrict purchase</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list
                          three-line
                          subheader
                        >
                          <v-subheader>General</v-subheader>
                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox v-model="notifications"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Notifications</v-list-item-title>
                              <v-list-item-subtitle>Notify me about updates to apps or games that I downloaded</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                    </v-list>-->
                  </v-card>
                </v-dialog>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-else>
          <!-- {{active}}//event -->
          <template v-if="active&&active.length&&active[0].indexOf('event')==-1">
            <div class="mb-8 mt-12 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>阶段名称：
              </div>
              <input
                v-model="progress.content.username"
                class="default-input py-1 subtitle-1"
                style="width:350px !important;"
                placeholder="阶段名称"
                type="text"
              >
            </div>
            <div class="mb-12 flex-align-start">
              <div
                class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                style="width:170px;"
              >
                <span class="red--text ml-1">*</span>排序：
              </div>
              <input
                v-model="progress.content.s"
                class="default-input py-1 subtitle-1"
                style="width:350px !important;"
                placeholder="排序"
                type="number"
              >
            </div>
            <div class="mb-4 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>是否允许前台创建事件：
              </div>
              <v-radio-group v-model="progress.content.status" row dense class="mt-0">
                <v-radio label="是" value="1" class="mr-8" />
                <v-radio label="否" value="0" />
              </v-radio-group>
            </div>
            <div class="mb-10 flex-align-start">
              <div
                class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
                style="width:170px;"
              />
              <v-btn color="#1976d2" depressed dark width="100">保存</v-btn>
            </div>
          </template>
          <template v-else>
            <div class="mb-8 mt-6 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>阶段名称：
              </div>
              <input
                v-model="information.username"
                class="default-input py-1 subtitle-1"
                style="width:350px !important;"
                placeholder="阶段名称"
                type="text"
              >
            </div>
            <div class="mb-8 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>任务列表：
              </div>
              <div class="flex-1 flex-column">
                <v-data-table
                  :headers="progress.task.headers"
                  :items="progress.task.list"
                  :items-per-page="search.pageSize"
                  class="pb-5 max-width"
                  hide-default-footer
                  item-key="id"
                  no-data-text="暂无查询数据"
                >
                  <template v-slot:item.active="{}" />
                </v-data-table>
                <v-btn color="#1976d2" class="mt-3" depressed dark text width="80">增加</v-btn>
              </div>
            </div>
            <div class="mb-8 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>提醒：
              </div>
              <div class="flex-1 flex-column">
                <v-data-table
                  :headers="progress.remind.headers"
                  :items="progress.remind.list"
                  :items-per-page="search.pageSize"
                  class="pb-5 max-width"
                  hide-default-footer
                  item-key="id"
                  no-data-text="暂无查询数据"
                >
                  <template v-slot:item.active="{}" />
                </v-data-table>
                <v-btn color="#1976d2" class="mt-3" depressed dark text width="80">增加</v-btn>
              </div>
            </div>
            <div class="mb-8 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>节点设置：
              </div>
              <input
                v-model="information.username"
                class="default-input py-1 subtitle-1"
                style="width:350px !important;"
                placeholder="节点设置"
                type="text"
              >
            </div>
            <div class="mb-8 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>前置条件：
              </div>
              <div class="flex-1 flex-column">
                <v-data-table
                  :headers="progress.front.headers"
                  :items="progress.front.list"
                  :items-per-page="search.pageSize"
                  class="pb-5 max-width"
                  hide-default-footer
                  item-key="id"
                  no-data-text="暂无查询数据"
                >
                  <template v-slot:item.active="{}" />
                </v-data-table>
                <v-btn color="#1976d2" class="mt-3" depressed dark text width="80">增加</v-btn>
              </div>
            </div>
            <div class="mb-8 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>是否允许前台创建子任务：
              </div>
              <v-radio-group v-model="information.status" row dense class="mt-0">
                <v-radio label="是" value="1" class="mr-8" />
                <v-radio label="否" value="0" />
              </v-radio-group>
            </div>
            <div class="mb-8 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>指派：
              </div>
              <input
                v-model="information.username"
                class="default-input py-1 subtitle-1"
                style="width:350px !important;"
                placeholder="指派"
                type="text"
              >
            </div>
            <div class="mb-8 flex-align-start">
              <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                <span class="red--text ml-1">*</span>附件：
              </div>
              <div class="flex-1 flex-column">
                <v-data-table
                  :headers="progress.files.flies"
                  :items="progress.files.list"
                  :items-per-page="search.pageSize"
                  class="pb-5 max-width"
                  hide-default-footer
                  item-key="id"
                  no-data-text="暂无查询数据"
                >
                  <template v-slot:item.active="{}" />
                </v-data-table>
                <v-btn color="#1976d2" class="mt-3" depressed dark text width="80">增加</v-btn>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>

    <!-- 批量激活statr -->
    <v-dialog v-model="activation.show" width="800">
      <v-card>
        <v-card-title class="font-weight-bold">批量激活</v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <div class="flex-1 flex-column overflow-auto px-3">
            <v-data-table
              :headers="activation.headers"
              :items="activation.list"
              :items-per-page="search.pageSize"
              class="pb-5 max-width"
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
            >
              <template v-slot:item.realname="{item}">
                <input
                  v-model="item.realname"
                  class="flex-1 default-input py-1 subtitle-1 white"
                  placeholder="用户名"
                  type="text"
                >
              </template>
              <template v-slot:item.organization="{item}">
                <v-menu
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="default-input white" color="primary" v-on="on">
                      <input
                        v-model="item.organization"
                        placeholder="试验类型"
                        class="max-width"
                        type="text"
                      >
                    </div>
                  </template>
                  <v-list class="pa-0 radius-0" subheader>
                    <!-- <v-list-item v-for="(item,index) in shiyan" :key="index">
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" class depressed @click="activation.show = false">取消</v-btn>
              <v-btn
                width="80"
                class="ml-3"
                color="primary"
                depressed
                @click.stop="activation.show = false"
              >确定</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 批量激活 end -->

    <!-- 关联项目 start -->
    <v-dialog v-model="projectDialog.show" width="800">
      <v-card>
        <v-card-title class="font-weight-bold">批量激活</v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <div class="flex-1 flex-column overflow-auto px-3">
            <v-data-table
              :headers="projectDialog.headers"
              :items="projectDialog.list"
              :items-per-page="search.pageSize"
              class="pb-5 max-width"
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" class depressed @click="projectDialog.show = false">关闭</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 关联项目 end -->
    <!-- </div> -->
    <v-dialog v-model="dialog.show" width="600">
      <v-card>
        <v-card-title class="font-weight-bold">添加文件夹</v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <div class="flex-1 flex-column overflow-auto mt-7 px-12">
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">文件夹名称：</div>
              <input
                v-model="dialog.data.username"
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="文件夹名称"
                type="text"
              >
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>父级文件夹：
              </div>
              <v-menu
                :close-on-content-click="false"
                bottom
                max-height="300"
                offset-overflow
                transition="slide-y-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <div class="default-input" color="primary" v-on="on">
                    <input
                      v-model="dialog.data.project_name"
                      placeholder="试验类型"
                      class="max-width"
                      type="text"
                    >
                  </div>
                </template>
                <v-list class="pa-0 radius-0" subheader>
                  <v-list-item v-for="(item,index) in shiyan" :key="index">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>排序：
              </div>
              <input
                v-model="dialog.data.s"
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="排序"
                required
                type="number"
              >
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" class="mr-3" color="primary" depressed @click.stop="submitAdd()">确定</v-btn>
              <v-btn width="80" class depressed @click="dialog.show = false">取消</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="drawer.show" temporary app right width="900">
      <form
        v-if="drawer.show"
        class="max-height flex-column overflow-hidden"
        @submit.prevent="submit()"
      >
        <div
          class="flex-0 flex-justify-between flex-y-center py-3 px-5"
          style="border-bottom:1px solid #e1e1e1"
        >
          <span class="text-h6">{{ drawer.data.id?'编辑事件':'新增事件' }}</span>
          <v-icon @click="drawer.show=false">mdi-close</v-icon>
        </div>
        <div class="flex-1 mb-8 mt-6 flex-column overflow-auto">
          <div class=" flex-0 mb-4 flex-align-start px-10">
            <div class="flex-0 flex-shrink-0 mt-2 font-weight-bold" style="width:170px;">
              <span class="red--text mr-2">*</span>阶段名称：
            </div>
            <form-item
              v-model="information.username"
              class="mr-0"
              placeholder="阶段名称"
              type="text"
            />
            <!-- <input
              class="default-input py-1 subtitle-1"
              style="width:350px !important;"
              placeholder="阶段名称"
              type="text"
              v-model="information.username"
            /> -->
          </div>
          <div class=" flex-0 mb-4 flex-align-start px-10">
            <div class="flex-0 flex-shrink-0 mt-2 font-weight-bold" style="width:170px;">
              里程碑：
            </div>
            <div class=" flex-y-center">
              <form-item
                v-model="information.level"
                :options="[
                  {
                    text:'是',
                    value:1
                  },
                  {
                    text:'否',
                    value:0
                  }]"
                class="flex-0 flex-shrink-0"
                style="width:130px;"
                direction=" left"
                placeholder="里程碑"
                type="radio"
                required
              />
              <span class=" flex-0 flex-shrink-0 ml-4">（若选“是”，前台该事件设置为里程碑）</span>
            </div>
            <!-- <form-item class="mr-0" placeholder="里程碑" type="text"
                     v-model="information.username"></form-item> -->
          </div>
          <div class=" flex-0 mb-4 flex-align-start px-10">
            <div class="flex-0 flex-shrink-0 mt-2 font-weight-bold" style="width:170px;">
              设置节点：
            </div>
            <form-item
              v-model="information.username"
              class=" flex-1 flex-shrink-0"
              :options="shiyan"
              placeholder="设置节点"
              multiple
              dense
              type="select"
            />
          </div>
          <div class=" flex-0 mb-4 flex-align-start px-10">
            <div class="flex-0 flex-shrink-0 mt-2 font-weight-bold" style="width:170px;">
              是否允许前台创建子任务：
            </div>
            <form-item
              v-model="information.level"
              :options="[
                {
                  text:'是',
                  value:1
                },
                {
                  text:'否',
                  value:0
                }]"
              class="flex-0 flex-shrink-0"
              style="width:130px;"
              direction=" left"
              type="radio"
              required
            />
          </div>
          <div class=" flex-column mb-5 px-10">
            <div class=" flex-justify-between mb-1">
              <span class="  font-weight-bold">任务列表：</span>
              <a>添加任务</a>
            </div>
            <div style="border:1px solid #e8e8e8;">
              <v-data-table
                :headers="drawer.task.headers"
                :items="drawer.task.list"
                class=" pb-5  header-grey"

                hide-default-footer
                item-key="id"
                dense
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.active>
                  <a @click="drawer.show=true">编辑</a>
                  <a @click="drawer.show=true">删除</a>
                </template>

              </v-data-table>
            </div>
          </div>
          <div class=" flex-column mb-5 px-10">
            <div class=" flex-justify-between mb-1">
              <span class="  font-weight-bold">提醒：</span>
              <a>添加提醒</a>
            </div>
            <div style="border:1px solid #e8e8e8;">
              <v-data-table
                :headers="drawer.remind.headers"
                :items="drawer.remind.list"
                class=" pb-5  header-grey"

                hide-default-footer
                item-key="id"
                dense
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.active>
                  <a @click="drawer.show=true">编辑</a>
                  <a @click="drawer.show=true">删除</a>
                </template>

              </v-data-table>
            </div>
          </div>
          <div class=" flex-column mb-5 px-10">
            <div class=" flex-justify-between mb-1">
              <span class="  font-weight-bold">前置条件：</span>
              <a>添加前置条件</a>
            </div>
            <div style="border:1px solid #e8e8e8;">
              <v-data-table
                :headers="drawer.front.headers"
                :items="drawer.front.list"
                class=" pb-5  header-grey"

                hide-default-footer
                item-key="id"
                dense
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.active>
                  <a @click="drawer.show=true">编辑</a>
                  <a @click="drawer.show=true">删除</a>
                </template>

              </v-data-table>
            </div>
          </div>
          <div class=" flex-column mb-5 px-10">
            <div class=" flex-justify-between mb-1">
              <span class="  font-weight-bold">必传附件：</span>
              <a>添加必传附件</a>
            </div>
            <div style="border:1px solid #e8e8e8;">
              <v-data-table
                :headers="drawer.files.headers"
                :items="drawer.files.list"
                class=" pb-5  header-grey"

                hide-default-footer
                item-key="id"
                dense
                no-data-text="暂无查询数据"
              >
                <template v-slot:item.active>
                  <a @click="drawer.show=true">编辑</a>
                  <a @click="drawer.show=true">删除</a>
                </template>

              </v-data-table>
            </div>
          </div>
        </div>
        <!-- <div class="flex-1 flex-column overflow-auto mt-7 mx-12 px-12">
          <template v-if="drawer.data.id">
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>用户账号：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="用户账号"
                required
                type="text"
                v-model="drawer.data.userName"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>姓名：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="姓名"
                required
                type="text"
                v-model="drawer.data.realname"
              />
            </div>
            <div v-if="tab==1" class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>部门：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="部门"
                required
                type="text"
                v-model="drawer.data.password"
              />
            </div>
            <div v-else class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>组织单位：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="组织单位"
                required
                type="text"
                v-model="drawer.data.confirmpassword"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">性别：</div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="性别"
                type="text"
                v-model="drawer.data.sex_dictText"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">邮箱：</div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="邮箱"
                type="text"
                v-model="drawer.data.email"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>联系电话：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="联系电话"
                required
                type="text"
                v-model="drawer.data.phone"
              />
            </div>
            <template v-if="tab==1">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>组织角色：
                </div>
                <v-menu
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="py-1" color="primary" v-on="on">
                      <input
                        placeholder="组织角色"
                        class="max-width"
                        type="text"
                        v-model="search.data.type"
                      />
                    </div>
                  </template>
                  <v-list class="pa-0 radius-0" subheader>
                    <v-list-item>
                      <v-list-item-title>内部用户</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>外部用户</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>设为主管：
                </div>
                <v-menu
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="py-1" color="primary" v-on="on">
                      <input
                        placeholder="设为主管"
                        class="max-width"
                        type="text"
                        v-model="search.data.type"
                      />
                    </div>
                  </template>
                  <v-list class="pa-0 radius-0" subheader>
                    <v-list-item>
                      <v-list-item-title>是</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>否</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template v-else>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>重置密码：
                </div>
                <v-radio-group v-model="dialog.data.status" row dense class="mt-0">
                  <v-radio label="是" value="1"></v-radio>
                  <v-radio label="否" value="0"></v-radio>
                </v-radio-group>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>密码生成：
                </div>
                <v-radio-group v-model="dialog.data.passwordType" row dense class="mt-0">
                  <v-radio label="通过电子邮件接收随机密码" value="1"></v-radio>
                  <v-radio label="直接显示系统随机密码" value="2"></v-radio>
                  <v-radio label="自定义密码" value="3"></v-radio>
                </v-radio-group>
              </div>
              <div class="mb-6 flex-align-start" v-if="dialog.data.passwordType==2">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>随机密码：
                </div>
                <span class="flex-1 subtitle-1">sdfgs</span>
              </div>
              <div class="mb-6 flex-align-start" v-else-if="dialog.data.passwordType==3">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>设置密码：
                </div>
                <input
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="联系电话"
                  required
                  type="text"
                  v-model="drawer.data.phone"
                />
              </div>
            </template>
          </template>
          <template v-else>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>重通讯录中选取：
              </div>
              <v-radio-group v-model="dialog.data.status" row dense class="mt-0">
                <v-radio label="是" value="1"></v-radio>
                <v-radio label="否" value="0"></v-radio>
              </v-radio-group>
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>组织单位：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="组织单位"
                required
                type="text"
                v-model="drawer.data.confirmpassword"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>用户账号：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="用户账号"
                required
                type="text"
                v-model="drawer.data.userName"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>姓名：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="姓名"
                required
                type="text"
                v-model="drawer.data.realname"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">性别：</div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="性别"
                type="text"
                v-model="drawer.data.sex_dictText"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">邮箱：</div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="邮箱"
                type="text"
                v-model="drawer.data.email"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>联系电话：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="联系电话"
                required
                type="text"
                v-model="drawer.data.phone"
              />
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>密码生成：
              </div>
              <v-radio-group v-model="dialog.data.passwordType" row dense class="mt-0">
                <v-radio label="通过电子邮件接收随机密码" value="1"></v-radio>
                <v-radio label="直接显示系统随机密码" value="2"></v-radio>
                <v-radio label="自定义密码" value="3"></v-radio>
              </v-radio-group>
            </div>
            <div class="mb-6 flex-align-start" v-if="dialog.data.passwordType==2">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>随机密码：
              </div>
              <span class="flex-1 subtitle-1">sdfgs</span>
            </div>
            <div class="mb-6 flex-align-start" v-else-if="dialog.data.passwordType==3">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                <span class="red--text ml-1">*</span>设置密码：
              </div>
              <input
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="联系电话"
                required
                type="text"
                v-model="drawer.data.phone"
              />
            </div>
          </template>
        </div>-->
        <div
          class="flex-0 flex-shrink-0 py-3 px-5 flex-justify-end"
          style="border-top:1px solid #e1e1e1;"
        >
          <v-btn type="submit" color="primary" width="100" depressed>提交</v-btn>
        </div>
      </form>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      hehe: false,
      tab: null,
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      active: [],
      trees: [
        {
          departName: '部门1',
          id: '1',
          children: [
            { id: 'event-12', departName: 'Calendar : app' },
            { id: 'event-23', departName: 'Chrome : app' },
            { id: 'event-34', departName: 'Webstorm : app' }
          ]
        },
        { departName: '部门2', id: '2' },
        { departName: '部门3', id: '3' },
        { departName: '部门4', id: '4' }
      ],
      open: [],
      rightTab: null,
      information: {},
      headers: [
        { text: '项目角色', value: 'rolename' },
        { text: '只读', value: 'readonly' },
        { text: '可写', value: 'writable' }
      ],
      list: [
        { rolename: 'chuhao', realname: '出号', id: '1' },
        { rolename: 'chuhao1', realname: '出号1', id: '2' }
      ],
      jurisdiction: {
        readonly: [],
        writable: []
      },
      process: {
        show: false,
        data: {}
      },
      progress: {
        content: {},
        headers: [
          { text: '', value: 'radio' },
          { text: '阶段名称', value: 'name' },
          { text: '排序', value: 'readonly' },
          { text: '是否允许前台创建事件', value: 'writable' },
          { text: '操作', value: 'active' }
        ],
        list: [
          { name: 'chuhao', realname: '出号', id: '1' },
          { name: 'chuhao1', realname: '出号1', id: '2' }
        ],
        chooseId: '',
        task: {
          headers: [
            { text: '任务名称', value: 'name' },
            { text: '操作', value: 'birthday' }
          ],
          list: [
            { name: 'chuhao', id: '1' },
            { name: 'chuhao1', id: '2' }
          ]
        },
        remind: {
          headers: [
            { text: '状态', value: 'state' },
            { text: '提醒语句', value: 'birthday' },
            { text: '提醒项目角色', value: 'realname' },
            { text: '操作', value: 'active' }
          ],
          list: [
            { state: 'chuhao', id: '1' },
            { state: 'chuhao1', id: '2' }
          ]
        },
        front: {
          headers: [
            { text: '阶段名称', value: 'name' },
            { text: '状态', value: 'birthday' },
            { text: '操作', value: 'active' }
          ],
          list: [
            { name: 'chuhao', id: '1' },
            { name: 'chuhao1', id: '2' }
          ]
        },
        files: {
          headers: [
            { text: '路径', value: 'path' },
            { text: '操作', value: 'active' }
          ],
          list: [
            { path: 'chuhao', id: '1' },
            { path: 'chuhao1', id: '2' }
          ]
        }
      },
      selected: [],
      activation: {
        show: false,
        headers: [
          { text: '姓名', value: 'username' },
          { text: '邮箱', value: 'birthday' },
          { text: '用户名', value: 'realname' },
          { text: '组织角色', value: 'organization' }
        ],
        list: [
          { username: 'chuhao', realname: '出号' },
          { username: 'chuhao1', realname: '出号1' }
        ]
      },
      drawer: {
        show: false,
        data: {},
        task: {
          headers: [
            { text: '任务名称', value: 'project_number', show: true },
            { text: '起止日期', value: 'project_name', show: true },
            { text: '操作', value: 'active' }
          ],
          list: [
            { project_name: '磷酸西格列汀片在空', project_number: 'JY202105001' }
          ]
        },
        remind: {
          headers: [
            { text: '任务状态', value: 'project_number', show: true },
            { text: '提醒语句', value: 'project_name', show: true },
            { text: '提醒用户', value: 'project_name', show: true },
            { text: '操作', value: 'active' }
          ],
          list: [
            { project_name: '磷酸西格列汀片在空', project_number: 'JY202105001' }
          ]
        },
        front: {
          headers: [
            { text: '阶段名称', value: 'name' },
            { text: '状态', value: 'birthday' },
            { text: '操作', value: 'active' }
          ],
          list: [
            { name: 'chuhao', id: '1' },
            { name: 'chuhao1', id: '2' }
          ]
        },
        files: {
          headers: [
            { text: '路径', value: 'path' },
            { text: '操作', value: 'active' }
          ],
          list: [
            { path: 'chuhao', id: '1' },
            { path: 'chuhao1', id: '2' }
          ]
        }
      },
      dialog: {
        show: false,
        type: '',
        data: {}
      },
      projectDialog: {
        show: false,
        headers: [
          { text: '项目编号', value: 'username' },
          { text: '方案编号', value: 'birthday' },
          { text: '项目名称', value: 'realname' }
        ],
        list: [
          { username: 'chuhao', realname: '出号' },
          { username: 'chuhao1', realname: '出号1' }
        ]
      },
      shiyan: [
        { name: '试验类型1', id: '1' },
        { name: '试验类型2', id: '2' },
        { name: '试验类型3', id: '3' }
      ]
    }
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      this.$http.get(this.$api.userList, { ...this.search }).then(res => {
        if (res.code === 0) {
          this.list = res.result.records || []
        }
      })
    },

    getProgressStageList() {
      const search = { ...this.search.data }
      this.$http.get(this.$api.progressStageList, { data: search }).then(res => {
        if (res.code === 0) {
          this.list = res.result.records || []
        }
      })
    },
    // 左侧树 点击
    chooseTree(e) {
      // let _f=false
      // let _obj={}
      // let _h=(arr,id)=>{
      //     arr.forEach(item=>{
      //     if(!_f){
      //         if(item.id==id){
      //         _f=true
      //         _obj={...item}
      //         }else{
      //         if(item.childrens&&item.childrens.length) _h(item.childrens,id)
      //         }
      //     }
      //     })
      // }
      // information.data.parentId
      // console.log(e)
      if (e && e.length) {
        // _h(this.trees,e[0])
        // this.tab="1"
        // this.information= {
        // show: true,
        // data: _obj
        // }
        // this.drawer.data.parentName=_obj.departName
        // this.drawer.data.parentId=_obj.id
        // this.information.data.parentName=_obj.departName
      }
    },
    // 编辑弹窗
    showEdit(item) {
      console.log(item)
      this.drawer.show = true
      this.drawer.data = { ...item }
      this.drawer.data.userName = this.drawer.data.username
    },
    addShow() {
      this.drawer.data = {}
      this.drawer.show = true
    },
    delShow() {
      this.$http
        .delete(this.$api.userDeleteBatch, { ids: this.selected.join(',') })
        .then(res => {
          if (res.code === 0) {
            this.getList()
          }
        })
    },
    queryByUserName(name) {
      // 获取代理人设置信息
      this.dialog.type = 'dailirenAdd'
      this.dialog.show = true
      this.dialog.data.userName = name
      this.$http
        .get(this.$api.queryByUserName, { userName: name })
        .then(res => {
          this.getMultiUser(res.result.agentUserName)
          this.dialog.type = 'dailirenEdit'
        })
    },
    getMultiUser(name) {
      // 获取代理人设置信息
      this.dialog.show = true
      this.$http.get(this.$api.getMultiUser, { username: name }).then(res => {
        console.log(res)
      })
    },
    sysUserAgentAdd() {
      // 代理人设置
      this.$http
        .delete(this.$api.sysUserAgentAdd, { ids: this.selected.join(',') })
        .then(res => {
          if (res.code === 0) {
            this.getList()
          }
        })
    },
    submitAdd() {},

    submit() {}
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  width: 130px;
  color: #777;
}
</style>
